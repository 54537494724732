import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
    this.state = { checked: false };
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      status: ""
    };
  }

  handleChange = (e) => {
    this.setState(
        {
          [e.target.name]: e.target.value,
          checked: !this.state.checked
        }
    )
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    const { status } = this.state;
    const content =
        <div className="col-50 hidden">

          <fieldset>

            <div className="row" >
              <div className="col-50">
                <div className="row">
                  <div className="col-50">
                    <label htmlFor="state">Etternavn/firmanavn</label>
                    <input className="input" type={'text'} name={'Kunde:gnr'}  id={'gnr'} required={true}/>
                  </div>
                  <div className="col-50">
                    <label htmlFor="zip">Fornavn/kontaktperson</label>
                    <input className="input" type={'text'} name={'Kunde:bnr'}  id={'bnr'} required={true}/>
                  </div>
                </div>

                {/*<div className="row">*/}
                {/*  <div className="col-75">*/}
                {/*    <label htmlFor="state">Opplysninger</label>*/}
                {/*    <input className="input" type={'text'} name={'Kunde:gnr'} id={'gnr'} required={true}/>*/}
                {/*  </div>*/}
                {/*</div>*/}

                <div className="row">
                  <div className="col-50">
                    <label htmlFor="state">Gate/vei/regningsadresse</label>
                    <input className="input" type={'text'} name={'Kunde:adresse'}  id={'gnr'} required={true}/>
                  </div>
                  <div className="col-15">
                    <label htmlFor="state">Postnummer</label>
                    <input className="input" type={'text'} name={'Kunde:postnummer'}  id={'gnr'} required={true}/>
                  </div>
                  <div className="col-15">
                    <label htmlFor="state">Poststed</label>
                    <input className="input" type={'text'} name={'Kunde:poststed'} id={'gnr'} required={true}/>
                  </div>                        </div>

                <div className="row">
                  <div className="col-15">
                    <label htmlFor="state">Telefon/mobiltelefon</label>
                    <input className="input" type={'text'} name={'Kunde:telefon'}  id={'gnr'} required={true}/>
                  </div>
                  <div className="col-15">
                    <label htmlFor="state">E-post</label>
                    <input className="input" type={'text'} name={'Kunde:epost'}  id={'gnr'} required={true}/>
                  </div>
                  <div className="col-50">
                    <label htmlFor="state">Fødelsdato/organisasjonsnummer</label>
                    <input className="input" type={'text'} name={'Kunde:fnr'}  id={'gnr'} required={true}/>
                  </div>
                </div>
                {/*<div className="col-75">*/}
                {/*  <label htmlFor="state">Opplysninger</label>*/}
                {/*  <input className="input" type={'text'} name={'Kunde:opplysninger'}  id={'gnr'} required={true}/>*/}
                {/*</div>*/}

                <div className="row">
                  <div className="col-15">
                    <label htmlFor="state">Dato</label>
                    <input className="input" type={'text'} name={'Kunde:dato'}  id={'gnr'} required={true}/>
                  </div>
                  <div className="col-15">
                    <label htmlFor="state">Sted</label>
                    <input className="input" type={'text'} name={'Kunde:sted'}  id={'gnr'} required={true}/>
                  </div>
                  <div className="col-50">
                    <label htmlFor="state">Navn</label>
                    <input className="input" type={'text'} name={'Kunde:navn'}  id={'gnr'} required={true}/>
                  </div>
                </div>


              </div>
            </div>
          </fieldset>
        </div>

    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Byggestrøm</h1>
              <form
                  onSubmit={this.submitForm}
                  action="https://formspree.io/f/xrgolajd"
                  method="POST"
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="Bestilling av byggstrøm" />

                <div className="field">
                  <label>
                    <input
                        name="oppsetting"
                        type="checkbox"
                        checked={this.state.OPPSETTING}
                        onChange={this.handleInputChange} /> Oppsetting
                  </label>
                </div>
                <div className="field">
                  <label>
                    <input
                        name="nedtaking"
                        type="checkbox"
                        checked={this.state.NEDTAKING}
                        onChange={this.handleInputChange} /> Nedtaking
                  </label>
                </div>
                <br></br>
                <div className="col-50">
                  <h3>Anleggsadresse</h3>
                  <fieldset>
                    <div className="row" >
                    <div className="col-50">
                      <div className="row">
                        <div className="col-50">
                          <label htmlFor="state">Gnr</label>
                          <input className="input" type={'text'} name={'anleggsaddresse:gnr'}  id={'gnr'}
                                 required={true}/>
                        </div>
                        <div className="col-50">
                          <label htmlFor="zip">Bnr</label>
                          <input className="input" type={'text'} name={'anleggsaddresse:bnr'} id={'bnr'}
                                 required={true}/>
                        </div>
                        <div className="col-50">
                          <label htmlFor="fnr">Fnr</label>
                          <input className="input" type={'text'} name={'anleggsaddresse:fnr'}  id={'fnr'}
                                 required={true}/>
                        </div>
                        <div className="col-75">
                          <label htmlFor="beskrivelse">Beskrivelse</label>
                          <input className="input" type={'text'} name={'anleggsaddresse:beskrivelse'} id={'beskrivelse'}
                                 required={true}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  </fieldset>
                </div>

                <br></br>

                <div className="col-50">
                  <h3>Bestiller</h3>
                  <fieldset>
                    <div className="row" >
                      <div className="col-50">
                        <div className="row">
                          <div className="col-15">
                            <label htmlFor="state">Etternavn/firmanavn</label>
                            <input className="input" type={'text'} name={'bestiller:etternavn'}  id={'gnr'} required={true}/>
                          </div>
                          <div className="col-15">
                            <label htmlFor="zip">Fornavn/kontaktperson</label>
                            <input className="input" type={'text'} name={'bestiller:fornavn'}  id={'bnr'} required={true}/>
                          </div>
                          <div className="col-15">
                            <label htmlFor="state">Telefon/mobiltelefon</label>
                            <input className="input" type={'text'} name={'bestiller:telefon'}  id={'gnr'} required={true}/>
                          </div>
                          <div className="col-15">
                            <label htmlFor="state">E-post</label>
                            <input className="input" type={'text'} name={'Kunde:epost'}  id={'gnr'} required={true}/>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-75">
                            <label htmlFor="state">Opplysninger</label>
                            <input className="input" type={'text'} name={'bestiller:opplysninger'}  id={'gnr'} required={true}/>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-15">
                            <label htmlFor="state">Dato</label>
                            <input className="input" type={'text'} name={'bestiller:dato'} id={'gnr'} required={true}/>
                          </div>
                          <div className="col-15">
                            <label htmlFor="state">Sted</label>
                            <input className="input" type={'text'} name={'bestiller:sted'}  id={'gnr'} required={true}/>
                          </div>
                          <div className="col-50">
                            <label htmlFor="state">Navn</label>
                            <input className="input" type={'text'} name={'bestiller:navn'}  id={'gnr'} required={true}/>
                          </div>
                        </div>


                      </div>
                    </div>
                  </fieldset>
                </div>

                <h3>Kunde (fakturamottaker)</h3>

                { content }

                <br/>
                <br/>


                <br/>

                <div>
                </div>
                <div className="field">
                  {status === "SUCCESS" ? <p>Takk for innsendingen!</p> : <button className="button is-link">Send</button>}
                  {status === "ERROR" && <p>Ooops! There was an error.</p>}
                </div>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    )
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}

